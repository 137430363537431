import React, { Component } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
//import Img from "gatsby-image/withIEPolyfill"
import { unorphan } from "../components/utilities"
class PageTemplate extends Component {
  constructor(props) {
    super(props);
    this.currentPage = this.props.data.wordpressPage

  }


  render() {
    const currentPage = this.currentPage

    return (
      <div >
        <Helmet>
          <meta charSet="utf-8" />

          <meta name="description" content={currentPage.title}></meta>




          <title>{"Gregory R. Miller & Co. – " + currentPage.title}</title>
          <link
            rel="canonical"
            href={"https://grmandco.com/" + currentPage.slug}
          />
        </Helmet>


        <h1 class="page_title" title={currentPage.title} dangerouslySetInnerHTML={{ __html: currentPage.title.trim() }} />
        <div class="main_content">



          <div class="news_content" title={currentPage.title} dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </div>
      </div>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      date(formatString: "MMMM DD, YYYY")
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`

/*           <meta property="og:site_name" content={"Gregory R. Miller & Co."}></meta>
          <meta property="og:title" content="European Travel Destinations"></meta>
          <meta property="og:description" content="Offering tour packages for individuals or groups."></meta>
          <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg"></meta>
          <meta property="og:url" content="http://euro-travel-example.com/index.htm"></meta>

          */
